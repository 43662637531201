import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Rating,
  CircularProgress,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CustomEmojiPicker from "./customemojipicker";
import Modal from "@mui/material/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { green } from "@mui/material/colors";

const FeedBackForm = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [brandUrl, setBrandUrl] = useState("");
  const [formExist, setFormExist] = useState(true);
  const [loading, setLoading] = useState(true);
  const [lockForm, setlockForm] = useState(false);
  const brand = decodeURIComponent(searchParams.get("brand"));
  const [open, setOpen] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    // bgcolor: "background.paper",
    backgroundColor: "#F6F6F6",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };


  const [formData, setFormData] = useState({
    reviewer_name: "",
    contact: "",
    user_email: "",
    review: "",
    rating: 0, // Added rating state
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // console.log(decodeURIComponent(searchParams.get("brand")));
  const handleRatingChange = (event, newValue) => {
    // console.log(newValue);
    setFormData({
      ...formData,
      rating: newValue,
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log(e.target);
      if (formData.rating === 0) {
        // Handle the case where rating is not provided
        alert("Please provide a rating.");
        return;
      }

      setlockForm(true);

      const brandData = {
        restaurant_name: brand ? brand : "",
        sub_zone: decodeURIComponent(searchParams.get("subzone")),
        id: searchParams.get("id"),
        review_date: new Date().toISOString(),
        orderType: "dining",
        mealTime: "others",
      };
      // throw new Error();
      const newformData = { ...formData, ...brandData };
      console.log("Form submitted:", newformData);
      const url =
        "https://prod.restaverse.com/api/automation/insert-feedback-form-data";
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(newformData),
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        // throw new Error(`HTTP error! status: ${response.status}`);
        console.log("something went wrong");
      } else {
        setFormData({
          reviewer_name: "",
          contact: "",
          user_email: "",
          review: "",
          rating: 0, // Added rating state
        });
      }
    } catch {
      setIsFormSubmitted(false);
    } finally {
      setOpen(true);
      setlockForm(false)
    }

    // }

    // Handle form submission logic here
  };

  useEffect(() => {
    const isFormValid = async () => {
      try {
        const formGuid = searchParams.get("guid");
        const sub_zone = decodeURIComponent(searchParams.get("subzone"));
        const id = searchParams.get("id");
        const response = await fetch(
          `https://prod.restaverse.com/api/automation/check-form-exist?guid=${formGuid}&subzone=${sub_zone}&id=${id}`
        );

        if (!response.ok) {
          console.log("hello");
          setFormExist(false);
        } else {
          const parsedResponse = await response.json();
          const logo = parsedResponse?.brand_logo || "";
          setBrandUrl(logo);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    isFormValid();
  }, []);

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#F6F6F6",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    ); // Display loading screen

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Ensure vertical stacking
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#F6F6F6",
      }}
    >
      {formExist ? (
        <>
          <img
            src={brandUrl}
            alt="Brand Logo"
            width={"110px"}
            height={"80px"}
            style={{ marginBottom: "10px", marginTop: "10px" }}
          />
          <Card
            sx={{
              maxWidth: 600,
              width: "100%",
              padding: 3,
              borderRadius: 2,
              boxShadow: "10px 10px #FFB22A", // Custom shadow with gold color
              pointerEvents: lockForm ? "none" : "auto",
              opacity: lockForm ? 0.5 : 1,
            }}
          >
            <CardContent>
              <Typography variant="h4" gutterBottom>
                ✨ We'd love to hear from you
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                Hey there! You‘re here because you‘re an awesome {brand + " "}
                customer! 👏We’d love to receive your feedback so that we can
                improve.
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2, // Adds spacing between form elements
                  alignItems: "stretch", // Ensures the form takes up the full width
                }}
              >
                <TextField
                  label="Your Name"
                  name="reviewer_name"
                  required
                  value={formData.reviewer_name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  placeholder="Enter your name"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Your Phone Number"
                      name="contact"
                      value={formData.contact}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      placeholder="Enter your phone number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Your Email ID"
                      name="user_email"
                      type="email"
                      value={formData.user_email}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      placeholder="Enter your email ID"
                    />
                  </Grid>
                </Grid>
                {/* Rating Component */}
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant="h6">Rating</Typography>
                  <Rating
                    name="rating"
                    required
                    value={formData.rating}
                    onChange={handleRatingChange}
                    size="large"
                    // precision={0.5}
                    sx={{
                      "& .MuiRating-iconFilled": {
                        color: "#FFB229", // Set the color for filled stars
                      },
                      "& .MuiRating-iconEmpty": {
                        color: "lightgray", // Set the color for empty stars
                      },
                    }}
                  />
                </Box>
                <TextField
                  label="Your Feedback"
                  name="review"
                  value={formData.review}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  placeholder="Enter your feedback"
                  multiline
                  rows={4} // Adjust the height of the text area
                />
                {/* <CustomEmojiPicker inputValue={formData.review} inputValueSetter={setFormData} /> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 2,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#FFB229",
                      textTransform:'None'
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...style, padding: 2 }}>
              {isFormSubmitted ? (
                <>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CheckCircleOutlineIcon
                      sx={{
                        color: green[500],
                        fontSize: 60,
                        marginInline: "auto",
                      }}
                    />
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    Thank You!
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                  >
                    Your feedback has been submitted successfully.
                  </Typography>
                </>
              ) : (
                <>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CancelIcon
                      sx={{
                        color: green[500],
                        fontSize: 60,
                        marginInline: "auto",
                      }}
                    />
                  </Box>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                  >
                    Feedback not submitted.
                  </Typography>
                </>
              )}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  sx={{ mt: 2, backgroundColor: "#FFB229" }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <Typography variant="h6" color="textSecondary">
          Page Not Found
        </Typography>
      )}
    </Box>
  );
};

export default FeedBackForm;
