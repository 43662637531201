import React, { useState } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notification from "../notification/notification";
import { useAppContext } from "../../Context/notification-context";

const defaultFormData = {
  name: "",
  email: "",
  linkedIn: "",
  workExperience: "",
  resumeFile: null,
  designation: "",
  location: "",
  contactNo: "",
  kpi: "job",
  expected_ctc: "", 
  expected_joining_date: "", 
  
};

const ModalForm = ({ triggerButton, designation, location, setNotification }) => {
  const [open, setOpen] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const { loading, setLoading, loadingContent, setLoadingContent } = useAppContext();
  const [formData, setFormData] = useState({
    ...defaultFormData,
    designation,
    location,
  });
  const [selectedFile, setSelectedFile] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgreementChange = () => {
    setAgreed(!agreed);
  };

  const handleInputChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleClose();
    setNotification(true);
    setLoading(true);
    setLoadingContent("Submitting your form");
    console.log(formData);

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("linkedIn", formData.linkedIn);
    formDataToSend.append("workExperience", formData.workExperience);
    formDataToSend.append("resumeFile", selectedFile, selectedFile.name);
    formDataToSend.append("designation", formData.designation);
    formDataToSend.append("location", formData.location);
    formDataToSend.append("contactNo", formData.contactNo);
    formDataToSend.append("kpi", formData.kpi);
    formDataToSend.append("expected_ctc", formData.expected_ctc); // New field
    formDataToSend.append("expected_joining_date", formData.expected_joining_date); // New field
    

    fetch("https://prod.restaverse.com/api/save-job-data-with-resume", {
      method: "POST",
      body: formDataToSend,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setFormData(defaultFormData);

        if (data && data.message === "Your response data") {
          setLoading(false);
          setLoadingContent("Your form has been submitted.");
          setTimeout(() => {
            console.log("Delayed for 1 second.");
            setNotification(false);
          }, 3000);
        } else {
          console.log("Unexpected data format or 'message' not found:", data);
        }
      })
      .catch((error) => {
        console.error("Error saving job data:", error);
      });
  };

  return (
    <div>
      {React.cloneElement(triggerButton, { onClick: handleClickOpen })}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Fill out the Form
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              margin="dense"
              label="Your Name"
              type="text"
              fullWidth
              value={formData.name}
              onChange={handleInputChange("name")}
              required
            />
            <TextField
              margin="dense"
              label="Your Email"
              type="email"
              fullWidth
              value={formData.email}
              onChange={handleInputChange("email")}
              required
            />
            <TextField
              margin="dense"
              label="Contact Number"
              type="text"
              fullWidth
              value={formData.contactNo}
              onChange={handleInputChange("contactNo")}
              required
            />
            <TextField
              margin="dense"
              label="LinkedIn"
              type="text"
              fullWidth
              value={formData.linkedIn}
              onChange={handleInputChange("linkedIn")}
              required
            />
            <TextField
              margin="dense"
              label="Work Experience"
              type="text"
              fullWidth
              value={formData.workExperience}
              onChange={handleInputChange("workExperience")}
              required
            />
            <TextField
              margin="dense"
              label="Expected CTC (Annual) in Rs"
              type="number"
              fullWidth
              value={formData.expected_ctc}
              onChange={handleInputChange("expected_ctc")}
              required
            />
            <TextField
              margin="dense"
              label="How soon can you join?"
              type="date"
              fullWidth
              value={formData.expected_joining_date}
              onChange={handleInputChange("expected_joining_date")}
              required
              InputLabelProps={{ shrink: true }}
            />
            <input
              type="file"
              onChange={onFileChange}
              accept="application/pdf"
              required
            />
            <FormControlLabel
              control={
                <Checkbox checked={agreed} onChange={handleAgreementChange} />
              }
              label={
                <Typography color="textPrimary">
                  I agree to &nbsp;
                  <a href="/policies/terms-and-services" target="_blank" rel="noopener noreferrer">
                    Restaverse's Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href="/policies/privacy-policy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                </Typography>
              }
            />
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "16px" }}
              type="submit"
              disabled={!agreed}
            >
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalForm;
