import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Routes,
  useLocation
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { AppProvider, useAppContext } from "./Context/notification-context";
import { GoogleOAuthProvider } from "@react-oauth/google";

import NavbarMain from "./Components/Navbar/navbar";
import VikhilNavbar from "./Components/Navbar/vikhil_nabvbar";
// import Reviews from "./Pages/Reviews/reviews";
import Footer from "./Components/Footer/footer";
import Career from "./Pages/Career/career";
import Team from "./Pages/Team/team";
import Casestudy from "./Pages/Casestudy/persiandarbarcasestudy";
import Casestudy2 from "./Pages/Casestudy/ufccasestudy"; 
import Services from "./Pages/Services/service";
import Airesponse from "./Pages/Ai_response/airesponse";
import Contactus from "./Pages/Contact/contactus";
import Home2 from "./Pages/home2/home2";
import UFCCasestudy from "./Pages/Casestudy/ufccasestudy";
import Featuredcasestudygreener from "./Pages/Casestudy/featuredcasestudygreener";
import Featuredcasestudygoma from "./Pages/Casestudy/featuredcasestudygoma";
import Featuredcasestudymeetha from "./Pages/Casestudy/featuredcasestudymeetha";
import Featuredcasestudyshm from "./Pages/Casestudy/featuredcasestudyshm";
import Featuredcasestudypersiadarbar from "./Pages/Casestudy/featuredcasestudypersiadarbar";
import PersiaDarbarCasestudy from "./Pages/Casestudy/persiadarbarcasestudy";
import Featuredcasestudypersiandarbar from "./Pages/Casestudy/featuredcasestudypersiandarbar";
import FeaturedcasestudyUFC from "./Pages/Casestudy/featuredcasestudyUFC";
import PersianDarbarCasestudy from "./Pages/Casestudy/persiandarbarcasestudy";
import PrivacyPolicy from "./Pages/Policies/PrivacyPolicy/privacypolicy";
import Policies from "./Pages/Policies/policies";
import TermsConditons from "./Pages/Policies/TermsConditions/termsconditions";
import TermsServices from "./Pages/Policies/TermsServices/termsservices";
import EndUserLicenceAgreement from "./Pages/Policies/EndUserLicenceAgreement/enduserlicenceagreement";
import FeaturedcasestudyUFC4 from "./Pages/Casestudy/featuredcasestudyUFC4";
import FeaturedcasestudyUFC5 from "./Pages/Casestudy/featuredcasestudyUFC5";
import FeaturedcasestudyUFC6 from "./Pages/Casestudy/featuredcasestudyUFC6";
import UFCCasestudy4 from "./Pages/Casestudy/ufccasestudy4";
import UFCCasestudy5 from "./Pages/Casestudy/ufccasestudy5";
import UFCCasestudy6 from "./Pages/Casestudy/ufccasestudy6";
import Greenr from "./Pages/Casestudy/greenr";
import Goma from "./Pages/Casestudy/goma";
import Shm from "./Pages/Casestudy/shm";
import Meetha from "./Pages/Casestudy/meetha";
import Test from "./Components/test/testing";
import Test2 from "./Components/test/test2";
import FeedBackForm from "./Pages/FeedBackForm/feedbackform";
import authReducer, { initialState } from "./reducers/authReducers";
import { useReducer } from "react";
import { LoginProvider, useLoginContext } from "./Context/login-context";
import ReactGA from "react-ga4";



function App() {
  ReactGA.initialize(process.env.NODE_ENV === 'development' ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID_DEV : process.env.REACT_APP_GOOGLE_ANALYTICS_ID_PROD);

  const [state, dispatch] = useReducer(authReducer, initialState);
  const { isLoggedIn, setIsLoggedIn, accessToken, setAccessToken } =
    useLoginContext();
  const location = useLocation();
  // const isFeedbackForm = location.pathname === '/feedback-form/*';
  const isFeedbackForm = location.pathname.startsWith('/feedback-form');


  // useEffect(() => {
  //   console.log(state.isLoggedIn);
  // }, [state.isLoggedIn]);
  console.log("app apps");
  return (
    <>
      <GoogleOAuthProvider clientId="696642202818-u4t5mqkt39mp9t9do521u6fa7k6lf12l.apps.googleusercontent.com">
        <AppProvider>
        
          {/* <VikhilNavbar /> */}
          {!isFeedbackForm && <VikhilNavbar />}
          <Routes>
          <Route path="/feedback-form/*" element={<FeedBackForm />} />
            <Route path="/" element={<Home2 />} />
            <Route path="/home" element={<Home2 />} />
            <Route path="/policies/*" element={<Policies />}>
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms-and-conditions" element={<TermsConditons />} />
              <Route path="terms-and-services" element={<TermsServices />} />
              <Route path="eula" element={<EndUserLicenceAgreement />} />
              <Route path="*" element={<PrivacyPolicy />} />
            </Route>
            <Route path="/about-us/join-us" element={<Career />} />
            <Route path="/about-us/our-team" element={<Team />} />
            <Route path="/services/know-more" element={<Services />} />
              <Route path="/services/ai-response" element={<Airesponse />} />
            <Route path="/contact-us" element={<Contactus />} />
            <Route
            path="/case-studies/Persian-Darbar/"
            element={<Featuredcasestudypersiandarbar />}
          />
             <Route
              path="/case-studies/United-farmers-creamery/"
              element={<FeaturedcasestudyUFC />}
            />
            <Route
              path="/case-studies/Persia-darbar/"
              element={<Featuredcasestudypersiadarbar />}
            />
            <Route
              path="/case-studies/United-farmers-creamery/"
              element={<Casestudy2 />}
            />
            <Route
              path="/case-studies/nom-nom/"
              element={<FeaturedcasestudyUFC4 />}
            />
            <Route
              path="/case-studies/Silver-beach-cafe/"
              element={<FeaturedcasestudyUFC5 />}
            />
            <Route
              path="/case-studies/The-bar-stock-exchange/"
              element={<FeaturedcasestudyUFC6 />}
            />
            <Route
              path="/case-studies/Greenr-cafe/"
              element={<Featuredcasestudygreener />}
            />
            <Route
              path="/case-studies/Goma/"
              element={<Featuredcasestudygoma />}
            />
            <Route
              path="/case-studies/Meetha/"
              element={<Featuredcasestudymeetha />}
            />
            <Route
              path="/case-studies/SHM/"
              element={<Featuredcasestudyshm />}
            />
           {isLoggedIn && (
              <>
                <Route
                  path="/case-studies/Persian-darbar/full-story"
                  element={<PersianDarbarCasestudy />}
                />
                <Route path="/case-studies/United-farmers-creamery/full-story" element={<UFCCasestudy />} />
                <Route
                  path="/case-studies/Persia-darbar/full-story"
                  element={<PersiaDarbarCasestudy />}
                />
                <Route path="/case-studies/nom-nom/full-story" element={<UFCCasestudy4 />} />
                <Route path="/case-studies/Silver-beach-cafe/full-story" element={<UFCCasestudy5 />} />
                <Route path="/case-studies/The-bar-stock-exchange/full-story" element={<UFCCasestudy6 />} />
                <Route
                  path="/case-studies/Greenr-cafe/full-story"
                  element={<Greenr/>}
                />
                <Route
                  path="/case-studies/Goma/full-story"
                  element={<Goma/>}
                />
                <Route
                  path="/case-studies/Shm/full-story"
                  element={<Shm/>}
                />
                <Route
                  path="/case-studies/Meetha/full-story"
                  element={<Meetha/>}
                />
              </>
            )}
            
            <Route path="*" element={<Home2 />} />
          </Routes>
          {!isFeedbackForm && <Footer />} 
        </AppProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
